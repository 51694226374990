<template>
  <b-modal id="bv-modal-example" v-model="modal" size="lg" hide-footer>
    <template #modal-title>
      {{ $t("betDetail.title") }} #{{ item.uuid || "No transaction ID" }}
    </template>
    <div v-if="item.gameType === 'Black Jack'">
      <div>
        <p class="mb-1">{{ $t("transactions.roundID") }} :</p>
        <h5 class="font-size-16">
          {{
            Object.entries(item).length && item.round
            ? item.round.identifyNumber
            : "No round"
          }}
        </h5>
      </div>
      <div v-if="Object.entries(item).length">
        <p class="mt-3 mb-1">{{ $t("betDetail.roulette") }} :</p>
        <h5 class="font-size-16">{{ item.game.name }}</h5>
      </div>
      <div v-if="Object.entries(item).length">
        <p class="mt-3 mb-1">{{ $t("betDetail.user") }}:</p>
        <h5 class="font-size-16">{{ item.player.username }}</h5>
      </div>
      <div v-if="Object.entries(item).length">
        <p class="mt-3 mb-1">{{ $t("tableReports.operator") }}:</p>
        <h5 class="font-size-16">{{ item.operator.name }}</h5>
      </div>
      <div v-if="Object.entries(item).length">
        <p class="mt-3 mb-1">{{ $t("filter.client") }}:</p>
        <h5 class="font-size-16">
          {{ item.client }}
        </h5>
      </div>
      <div>
        <b-table :items="item.bet" :fields="fields"></b-table>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    item: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      fields: 'reports/getFields'
    })
  },
  data() {
    return {
      modal: false
    }
  }
}
</script>